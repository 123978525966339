<template>
  <AppLayout>
    <div class="container">
      <div class="container-top">
        <el-image
          v-if="dataList[0]?.clickEvent === 1"
          class="imgs-top-1"
          :src="dataList[0]?.pictureUrl"
        >
          <template #error>
            <div class="image-slot">
              <el-icon><icon-picture /></el-icon>
            </div>
          </template>
        </el-image>
        <el-image
          v-else
          class="imgs-top"
          :src="dataList[0]?.pictureUrl"
          @click="handleOpen(dataList)"
        >
          <template #error>
            <div class="image-slot">
              <el-icon><icon-picture /></el-icon>
            </div>
          </template>
        </el-image>
      </div>
      <div class="message-two">
        <div class="support-page">
          <div class="message-t">
            <p class="message">消息中心</p>
            <p
              class="badge"
              v-for="(tag, index) in titleList"
              :key="tag.id + index"
              :class="{ active: tag.messageType === currentTagTerrace }"
              @click="TagTerrace(tag)"
            >
              <el-badge v-if="tag.readState == 0" is-dot>{{
                tag.messageType
              }}</el-badge>
              <el-badge v-else>{{ tag.messageType }}</el-badge>
            </p>
          </div>
        </div>

        <div class="list-container" v-if="messageList">
          <div v-for="item in messageList" :key="item.id">
            <div class="get-con" v-if="item.messageType === '订单消息'">
              <div class="get-con-2">
                <span>{{ item.messageTitle }}</span>
                <span>{{ item.createTime }}</span>
              </div>
              <div class="get-con-3">
                <span>{{ item.messageContent }}</span>
                <span @click="messageVal">查看详情</span>
              </div>
            </div>
            <div class="get-top" v-else>
              <img :src="item.picture" alt="" />
              <div class="get-btn">
                <div class="get-c">
                  <span>{{ item.messageTitle }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="get-d">
                  <span :title="item.messageContent">{{
                    item.messageContent
                  }}</span>
                  <span @click="$router.push(`/messageDetails/${item.id}`)"
                    >查看详情</span
                  >
                </div>
              </div>
            </div>
          </div>
          <noContent v-if="messageList == ''" />
          <div class="demo-pagination-block" v-if="msgData.total">
            <el-pagination
              :current-page="msgData.current"
              :page-size="msgData.size"
              :small="small"
              background
              layout="prev, pager, next, jumper,total"
              :total="msgData.total"
              next-text="下一页"
              prev-text="上一页"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <el-button size="mini" class="but-on">确定</el-button>
          </div>
          <div v-if="msgData.total == ''"></div>
        </div>
      </div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { ref, reactive, onMounted } from "vue";
import {
  MessageDetails,
  MessageRed,
  MessageImg,
} from "../../api/messageDetail/index.js";
import { useRouter } from "vue-router";
import emitter from "@/utils/eventBus";
import noContent from "../../components/directives/noContent";

const currentTagTerrace = ref("全部消息");

// 点击标题
const TagTerrace = (item) => {
  console.log(item);
  if (item.readState === 0) {
    item.readState = 1;
  }
  const token = localStorage.getItem("token");
  let val = ref("全部消息");
  let vals = ref("订单消息");
  if (item.messageType === vals.value) {
    if (token) {
      console.log(token);
    } else {
      emitter.emit("openLogin", { openLogin: true });
    }
  }
  if (item.messageType === val.value) {
    query.messageType = "";
    currentTagTerrace.value = item.messageType;
    getMessageDetails((query.messageType = ""));
  } else {
    currentTagTerrace.value = item.messageType;
    getMessageDetails((query.messageType = currentTagTerrace.value));
  }
};
onMounted(() => {
  getMessageRed();
  getMessageDetails();
  getMessageImg();
});
// 跳转
const handleOpen = (v) => {
  console.log(v);
  if (v[0].clickEvent === 0) {
    if (v[0].link != null && v[0].link != undefined) {
      if (v[0].openWay === 0) {
        window.open(v[0].link);
      } else {
        window.location.href = v[0].link;
      }
    }
  } else {
    return;
  }
};
// 分页
const handleCurrentChange = (pageNum) => {
  console.log(pageNum, "handleCurrentChange22222");
  getMessageDetails((query.pageNum = pageNum));
};
// 左侧标题
const titleList = ref([]);
const getMessageRed = async () => {
  try {
    let data = await MessageRed();
    if (data.code === 200) {
      titleList.value = data.data;
      titleList.value.unshift({
        id: "185c5c527a264787abcccfab804b38bd01",
        messageType: "全部消息",
        readState: 1,
        sort: 1,
      });
      getMessageDetails();
    } else {
      return "暂无数据";
    }
  } catch (error) {
    console.log(error);
  }
};
// 顶部图片
let dataList = ref({});
const getMessageImg = async () => {
  try {
    let res = await MessageImg(8);
    if (res.code === 200) {
      if (res.data[0].systemType === 0) {
        dataList.value = res.data;
        console.log(res);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
// 参数
const query = reactive({
  pageNum: "",
  pageSize: "",
  messageType: "",
});
// 详情页面
const msgData = ref([]); //分页
const messageList = ref([]);
const getMessageDetails = async () => {
  try {
    let data = await MessageDetails(query);
    if (data.code === 200) {
      msgData.value = data.data;
      messageList.value = data.data.records;
      console.log(messageList.value);
    } else {
      return "暂无数据";
    }
  } catch (error) {
    console.log(error);
  }
};
const router = useRouter();
const messageVal = () => {
  const token = localStorage.getItem("token");
  if (token) {
    router.push({
      path: "/orderCenter",
    });
  } else {
    emitter.emit("openLogin", { openLogin: true });
  }
};
</script>

<style lang="less" scoped>
.demo-pagination-block {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.container {
  width: 1920px;
  height: 100%;
  background-color: #f2f2f2;
  .container-top {
    margin-bottom: 30px;
    .imgs-top:hover {
      cursor: pointer;
    }
  }
  .message-two {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    .support-page {
      width: 200px;
      height: 900px;
      background: #ffffff;
      .message-t {
        width: 200px;
        display: flex;
        flex-direction: column;
        margin-left: 38px;
        margin-top: 48px;
        .message {
          font-size: 18px;
          color: #333333;
          margin-bottom: 30px;
        }
        .badge {
          color: #666;
          font-size: 15px;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            color: #ce1200;
          }
          &.active {
            color: #ce1200;
          }
        }
      }
    }
    .list-container {
      width: 980px;
      margin-left: 20px;
      .get-top {
        width: 980px;
        height: 380px;
        background: #fff;
        margin-bottom: 10px;
        img {
          display: inline-block;
          width: 900px;
          height: 300px;
          margin: 40px;
        }
        .get-btn {
          width: 900px;
          height: 100px;
          background: #000000;
          opacity: 0.7;
          position: relative;
          bottom: 139px;
          left: 40px;
          .get-c {
            display: flex;
            justify-content: space-between;
            padding-top: 16px;
            padding-bottom: 18px;
            margin-left: 20px;
            :nth-child(1) {
              font-size: 14px;
              color: #ffffff;
            }
            :nth-child(2) {
              font-size: 11px;
              color: #ffffff;
              margin-right: 20px;
            }
          }
          .get-d {
            display: flex;
            justify-content: space-between;
            margin-left: 20px;
            :nth-child(1) {
              width: 724px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            :nth-child(2) {
              width: 100px;
              height: 30px;
              line-height: 28px;
              border: 1px solid #ce1200;
              border-radius: 5px;
              margin-right: 20px;
              font-size: 12px;
              color: #ce1200;
              text-align: center;
              cursor: pointer;
              &:hover {
                background: #ce1200;
                color: #fff;
              }
            }
          }
        }
      }
      .get-con {
        width: 980px;
        height: 173px;
        background: #ffffff;
        margin-bottom: 10px;
        .get-con-2 {
          display: flex;
          justify-content: space-between;
          margin-left: 60px;
          padding-top: 50px;
          padding-bottom: 18px;
          :nth-child(1) {
            font-size: 14px;
            color: #333333;
          }
          :nth-child(2) {
            font-size: 11px;
            color: #999999;
            padding-right: 60px;
          }
        }
        .get-con-3 {
          display: flex;
          justify-content: space-between;
          margin-left: 60px;
          :nth-child(1) {
            width: 724px;
            font-size: 12px;
            color: #666666;
          }
          :nth-child(2) {
            width: 100px;
            height: 30px;
            line-height: 28px;
            border: 1px solid #ce1200;
            border-radius: 5px;
            font-size: 12px;
            color: #ce1200;
            text-align: center;
            margin-right: 60px;
            cursor: pointer;
            &:hover {
              background: #ce1200;
              color: #fff;
            }
          }
        }
      }
      .demo-pagination-block {
        display: flex;
        justify-content: center;
        margin-top: 36px;
        margin-bottom: 60px;
        .but-on {
          margin-left: 10px;
          height: 36px;
        }
      }
      ::v-deep .el-pagination.is-background .el-pager li {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background: transparent;
      }
      ::v-deep .el-pagination.is-background .el-pager li.is-active {
        background-color: #ce1200;
        color: #fff;
        border: #ce1200;
      }
      ::v-deep .el-pager li:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
      }
      ::v-deep .el-pagination button:hover {
        color: #ce1200;
        border: 1px solid #ce1200;
      }
      ::v-deep .el-pagination.is-background .btn-next {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
      ::v-deep .el-pagination.is-background .btn-prev {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
      ::v-deep .el-input__wrapper {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
      ::v-deep .el-button:focus,
      .el-button:hover {
        color: #666;
        border: 1px solid #d9d9d9;
        background-color: #fff;
        outline: 0;
      }
    }
  }
}
::v-deep .el-badge__content.is-fixed.is-dot {
  right: 1px;
  top: 1px;
}
.el-image {
  width: 1920px;
  height: 300px;
}
</style>
